<template>
  <b-container data-aos="fade-up">
    <h1 class="mt-5 mb-2 text-primary">Good Practice Guide</h1>
    <p>
      <i>
        Volunteer drivers are expected to treat their passengers with care and
        consideration being mindful of individual needs and offering help if
        necessary.
      </i>
    </p>
    <h2 class="text-primary">Collecting the Passenger</h2>
    <ul>
      <li>Always arrive on time and have your ID card visible.</li>
      <li>
        Any unexpected mobility problems should be reported to the Co-ordinator.
      </li>
      <li>If appropriate ensure the house is secured before leaving.</li>
      <li>
        A driver is not expected to lift or push a wheelchair unless this has
        been previously agreed and does so at their own risk.
      </li>
    </ul>
    <h2 class="text-primary">The Journey</h2>
    <ul>
      <li>
        Set car trip indicator before departure so you can keep an accurate
        check on mileage covered.
      </li>
      <li>
        Before setting off it is a good idea to check the passenger destination
        and if attending hospital check the appointment time and clinic.
      </li>
      <li>
        It may be helpful, but not essential for a driver to carry a mobile
        phone-this could assist with communicating with a passenger eg. when
        waiting to collect them, if there has been an unforeseen delay.
      </li>
      <li>Ensure all passengers are wearing seat belts.</li>
      <li>
        In the event of an accident ensure the passengers are not in a dangerous
        position and contact the emergency services. Report incident to the
        Co-ordinator asap.
      </li>
    </ul>
    <h2 class="text-primary">Hospital Appointments</h2>
    <ul>
      <li>
        On arrival – Depending on the confidence and mobility of the passenger
        it may be necessary for a driver to accompany them into the hospital.
        Once inside a driver may continue with the passenger to the clinic.
        Drivers should make it clear to the passenger where they will collect
        them from.
      </li>
      <li>
        Waiting – It is not necessary for a driver to remain with the passenger
        at the clinic but occasionally an elderly or nervous client may need
        reassurance. In this case a driver is expected to provide this. It is
        impossible to give a guide to waiting times. It may be possible to
        ascertain the predicted length of appointment on arrival but not always.
      </li>
      <li>
        If the Co-ordinator knows in advance that an appointment will be in
        excess of 2 to 3 hrs then two journeys may be arranged.
      </li>
      <li>
        Parking – Although all hospitals are different there is usually a drop
        off point at the hospital entrance. A car may be left there for 10 to 15
        minutes to give time for the driver to accompany the passenger to the
        appropriate clinic if necessary.
      </li>
      <li>
        The car must then be moved to the parking area. Always wear your
        identity badge and use your TH&amp;W parking permit as required.
      </li>
      <li>
        If payment has to be made at other medical sites or elsewhere for
        parking, the cost must be met by the passenger.
      </li>
    </ul>

    <h2 class="text-primary">Return Journey</h2>

    <p>
      Drivers must be aware that a passenger may be affected by treatment they
      have received (dizziness, impaired sight etc ) and should care for the
      passenger accordingly. A passenger must only be returned to their home or
      to a previously agreed address, unless they have told the Co-ordinator
      that they will not need a return trip. Request payment at conclusion of
      journey –mileage rounded up to nearest 45p (+ any parking charges
      incurred) and be prepared to give change.
    </p>
    <h2 class="text-primary">Dropping off a Passenger</h2>

    <p>
      Care must be taken to return passengers safely at their home or other
      destination as agreed. Passengers with impaired mobility should be
      accompanied to the door and if returning home, assisted to unlock the door
      if necessary. Drivers may need to ensure that the passenger has entered
      their home or other destination safely before driving away.
    </p>
    <h2 class="text-primary">General safeguards</h2>

    <h3 class="text-info">Financial Matters</h3>
    <ul>
      <li>
        Drivers should not pay bills or collect pensions on behalf of someone
        else.
      </li>
      <li>
        Drivers should not assist in writing a will or acting as a witness.
      </li>
      <li>Drivers should not assist in filling in forms.</li>
      <li>
        Drivers should not give advice on benefits but should direct passengers
        to contact their local benefit office.
      </li>
    </ul>
    <h3 class="text-info">Gifts</h3>
    <p>
      The acceptance of gifts from those you are helping should be treated with
      caution. Gifts of chocolate or items of low value may be accepted with
      discretion but items of high value or sums of money should be refused.
    </p>

    <p>
      <strong>
        CONFIDENTIALITY IN TERMS OF PERSONAL DETAILS OF BOTH PASSENGER AND OTHER
        VOLUNTEERS MUST BE RESPECTED AT ALL TIMES.
      </strong>
    </p>
  </b-container>
</template>

<script>
export default {
  name: "GoodPracticeGuide",
};
</script>
